import React from "react";
import CountdownTimer from "./CountdownTimer";

export default function Dashboard() {
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 3);
  targetDate.setHours(targetDate.getHours() + 13);
  targetDate.setMinutes(targetDate.getMinutes() + 48);
  return (
    <div>
      <header>
        <div className="w-full md:px-16 px-6 py-4">
          <div className="flex items-center gap-1">
            <img src="logo.svg" alt="" className="" />
            <h1 className="text-4xl font-semibold">Novus</h1>
          </div>
        </div>
      </header>
      <div className=" flex lg:flex-row flex-col gap-4 md:px-16 px-6 md:mt-12 mt-8 mb-10">
        <div className="lg:w-[65%] flex flex-col gap-4 ">
          <div className="bg-[#0E1329] rounded-xl">
            <div className="flex gap-4 items-center py-4 md:px-12 px-8">
              <img
                src="/user2.svg"
                alt=""
                width={40}
                className="-mt-1 md:w-[40px] w-[30px]"
              />
              <div className="flex flex-col">
                <p className="md:text-base text-sm">Welcome back!</p>
                <h1 className="md:text-2xl text-xl font-semibold">
                  Alex Harmoni
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-3 py-10 md:px-12 px-8 border-t border-[#4E5C96]">
              <div className="flex min-[520px]:flex-row flex-col gap-4">
                <div className="bg-[#35478A] flex items-center md:justify-normal cursor-pointer justify-center gap-2 md:py-3 py-2 md:px-6 px-2 min-[520px]:w-1/3 rounded-md">
                  <img
                    src="/fb.svg"
                    alt=""
                    width={15}
                    className="md:w-[15] w-[10]"
                  />
                  <h1 className="md:text-xl">Facebook</h1>
                </div>
                <div className="bg-[#35478A] flex items-center md:justify-normal cursor-pointer justify-center gap-2 md:py-3 py-2 md:px-6 px-2 min-[520px]:w-1/3 rounded-md">
                  <img
                    src="/worldlogo.svg"
                    alt=""
                    width={25}
                    className="md:w-[25] w-[20]"
                  />
                  <h1 className="md:text-xl">World ID</h1>
                </div>
                <div className="bg-[#1E2748] flex items-center md:justify-normal cursor-pointer justify-center gap-2 md:py-3 py-2 md:px-6 px-2 min-[520px]:w-1/3 rounded-md">
                  <img
                    src="/reddit.svg"
                    alt=""
                    width={25}
                    className="md:w-[25] w-[20]"
                  />
                  <h1 className="md:text-xl">Reddit</h1>
                </div>
              </div>
              <div className="flex min-[520px]:flex-row flex-col gap-4">
                <div className="bg-[#35478A] flex items-center md:justify-normal cursor-pointer justify-center gap-2 md:py-3 py-2 md:px-6 px-2 min-[520px]:w-1/3 rounded-md">
                  <img
                    src="/x2.svg"
                    alt=""
                    width={25}
                    className="md:w-[25] w-[20]"
                  />
                  <h1 className="md:text-xl">Twitter</h1>
                </div>
                <div className="bg-[#1E2748] flex items-center md:justify-normal cursor-pointer justify-center gap-2 md:py-3 py-2 md:px-6 px-2 min-[520px]:w-1/3 rounded-md">
                  <img
                    src="/linkedin.svg"
                    alt=""
                    width={25}
                    className="-mt-2 md:w-[25] w-[20]"
                  />
                  <h1 className="md:text-xl">LinkedIn</h1>
                </div>
                <div className="bg-[#1E2748] flex items-center md:justify-normal cursor-pointer justify-center gap-2 md:py-3 py-2 md:px-6 px-2 min-[520px]:w-1/3 rounded-md">
                  <img
                    src="/insta.svg"
                    alt=""
                    width={25}
                    className="md:w-[25] w-[20]"
                  />
                  <h1 className="md:text-xl">Instagram</h1>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 py-8 md:px-12 px-8 border-t border-[#4E5C96]">
              <div className="flex justify-between gap-2 items-center">
                <div className="flex flex-col md:gap-1 gap-2">
                  <p className="md:text-base text-sm">
                    Completed Assessments:
                    <span className="font-extrabold"> 14</span>
                  </p>
                  <p className="md:text-base text-sm">
                    Completed Questionnaires:
                    <span className="font-extrabold"> 21</span>
                  </p>
                  <p className="md:text-base text-sm">
                    Cryptocurrency Earned:
                    <span className="font-extrabold"> 631.34 USDC </span>
                  </p>
                </div>
                <div className="flex flex-col gap-2 py-3 md:px-7 px-5 bg-[#1E2748] rounded-md">
                  <p className="text-sm">Time till next Assessment</p>
                  <h1 className="md:text-4xl text-2xl font-semibold">
                    {/* 3d 13h 48m */}
                    <CountdownTimer targetDate={targetDate} />
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#0E1329] rounded-xl">
            <div className="flex items-center gap-2 py-6 md:px-12 px-8">
              <img src="/claim.svg" alt="" width={20} />
              <h1 className="text-lg font-semibold">Claim WLD</h1>
            </div>
            <div className="border-t border-[#4E5C96] py-10 md:px-12 px-8 flex md:flex-row flex-col gap-4">
              <div className="md:w-[66%]">
                <div className="flex w-full gap-4">
                  <div className="flex w-1/2 flex-col gap-1 py-3 px-4 bg-[#1E2748] rounded-md">
                    <p className="text-sm">Claimable Rewards</p>
                    <h1 className="md:text-3xl text-xl font-semibold">
                      243.76 WLD
                    </h1>
                  </div>
                  <div className="flex w-1/2 flex-col gap-1 py-3 px-4 bg-[#1E2748] rounded-md">
                    <p className="text-sm">Total Rewards Earned</p>
                    <h1 className="md:text-3xl text-xl font-semibold">
                      934.6 WLD
                    </h1>
                  </div>
                </div>
                <button className="cstm-btn md:py-3 py-2 md:px-12 px-8 rounded-md mt-8">
                  Claim 243.76
                </button>
              </div>
              <div className="md:w-[34%] flex justify-center">
                <img src="/graph.svg" alt="" width={200} />
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[35%] bg-[#0E1329] rounded-xl">
          <div className="flex items-center gap-2 py-6 pt-9 px-6">
            <img src="/noti.svg" alt="" width={25} />
            <h1 className="text-lg font-semibold">Notifications</h1>
          </div>
          <div className="border-t border-[#4E5C96] px-6 py-8 h-[640px] cstm-scroll overflow-y-scroll">
            <div className="flex flex-col gap-3 h-[150%]">
              <div className="bg-[#1E2748] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">
                    New Assessment is Available
                  </h1>
                  <p className="text-[#3C4963] md:text-base text-sm">05:29</p>
                </div>
                <p className="text-sm">
                  New Assessment just dropped, click on the button below to take
                  the assessment
                </p>
                <div className="flex items-center gap-2 md:text-sm text-xs mt-2 cursor-pointer">
                  <p>Go to Dashboard</p>
                  <img src="/arrow.svg" alt="" />
                </div>
              </div>
              <div className="bg-[#131A34] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">Reward Claimed!</h1>
                  <p className="text-[#3C4963] md:text-base text-sm">10:27</p>
                </div>
                <p className="text-sm">
                  Congratulations, you have successfully received 10USDC as a
                  reward for the assessment
                </p>
              </div>
              <div className="bg-[#131A34] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">Reward Claimed!</h1>
                  <p className="text-[#3C4963] md:text-base text-sm">10:27</p>
                </div>
                <p className="text-sm">
                  Congratulations, you have successfully received 10USDC as a
                  reward for the assessment
                </p>
              </div>
              <div className="bg-[#131A34] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">
                    Assessment Completed
                  </h1>
                  <p className="text-[#3C4963] md:text-base text-sm">10:27</p>
                </div>
                <p className="text-sm">
                  Congratulations, you have successfully received 10USDC as a
                  reward for the assessment
                </p>
              </div>
              <div className="bg-[#131A34] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">
                    Assessment Completed
                  </h1>
                  <p className="text-[#3C4963] md:text-base text-sm">10:27</p>
                </div>
                <p className="text-sm">
                  Congratulations, you have successfully received 10USDC as a
                  reward for the assessment
                </p>
              </div>
              <div className="bg-[#131A34] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">
                    Assessment Completed
                  </h1>
                  <p className="text-[#3C4963] md:text-base text-sm">10:27</p>
                </div>
                <p className="text-sm">
                  Congratulations, you have successfully received 10USDC as a
                  reward for the assessment
                </p>
              </div>
              <div className="bg-[#131A34] p-4 flex flex-col gap-2">
                <div className="w-full flex justify-between items-center gap-2">
                  <h1 className="md:text-lg font-semibold">
                    Assessment Completed
                  </h1>
                  <p className="text-[#3C4963] md:text-base text-sm">10:27</p>
                </div>
                <p className="text-sm">
                  Congratulations, you have successfully received 10USDC as a
                  reward for the assessment
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 text-sm mt-8 md:mb-0 mb-4 cursor-pointer">
            <p>View More</p>
            <img src="/arr2.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
